// Custom tamagui button
// Reference: https://tamagui.dev/docs/components/button

import {
  ButtonFrame,
  ButtonText,
  GetProps,
  Spinner,
  ButtonProps as TamaguiButtonProps,
  isWeb,
  styled,
  useButton,
} from 'tamagui'

const CustomButtonFrame = styled(ButtonFrame, {
  borderRadius: '$2',
  variants: {
    plain: {
      true: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        hoverStyle: {
          backgroundColor: '$overlay2',
          opacity: 0.9,
        },
        focusStyle: {
          backgroundColor: '$overlay2',
          opacity: 0.8,
          outlineWidth: 0,
          borderWidth: 0,
        },
        pressStyle: {
          backgroundColor: '$overlay2',
          opacity: 0.6,
          outlineWidth: 0,
          borderWidth: 0,
        },
      },
    },
    outlined: {
      true: {
        bw: 2,
        boc: "$black",
        focusStyle: {
          boc: "$black",
          bw: 2,
          outlineWidth: 0,
          opacity: 0.8,
        },
        pressStyle: {
          boc: "$black",
          outlineWidth: 0,
          opacity: 0.8,
        },
        hoverStyle: {
          boc: "$black",
          outlineWidth: 0,
          opacity: 0.8,
        }
      }
    }
  } as const,
})

const CustomButtonText = styled(ButtonText, {
  fontWeight: '600',
  textAlign: 'center',
  variants: {
    plain: {
      true: {
        hoverStyle: {
          opacity: 0.8,
        },
        focusStyle: {
          opacity: 0.7,
        },
        pressStyle: {
          opacity: 0.75,
        },
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
        letterSpacing: 1.2,
      },
    },
  } as const,
})

// to capture the custom variant types you define
type CustomButtonFrameProps = GetProps<typeof CustomButtonFrame>
type CustomButtonTextProps = GetProps<typeof CustomButtonText>

export type ButtonProps = TamaguiButtonProps &
  CustomButtonFrameProps &
  CustomButtonTextProps & {
    loading?: boolean
  }

export const Button = CustomButtonFrame.styleable<ButtonProps>((propsIn, ref) => {
  const { loading, uppercase, fontWeight = '600', ...rest } = propsIn

  const textProps = {
    uppercase,
    fontWeight,
  }

  const buttonProps = {
    ...rest,
    ...(loading && {
      icon: <Spinner />,
      disabled: true,
    }),
  }

  const { props } = useButton(buttonProps, {
    Text: (props) => <CustomButtonText {...props} {...textProps} />,
  })

  // HACK: This is to ensure that new tabs are opened on web
  if(props.target === '_blank' && isWeb) {
    props['onClick'] = (e: any) => e.stopPropagation();
  }

  return <CustomButtonFrame {...props} ref={ref} />
})

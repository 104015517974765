import { Button, Input, InputProps, XStack, XStackProps } from 'tamagui'
import { Search, X } from '@tamagui/lucide-icons'

interface SearchFieldProps {
  value: string
  inputProps?: InputProps
  onSearchChange?: (value: string) => void
  onSubmit?: (value: string) => void
}

export const SearchField = ({
  value,
  inputProps,
  onSearchChange,
  onSubmit,
  ...props
}: SearchFieldProps & XStackProps) => {
  const clearInput = () => {
    if (onSearchChange) {
      onSearchChange('')
    }
  }

  return (
    <XStack
      position="relative"
      bg="$white"
      opacity={0.75}
      br={8}
      py="$2"
      hoverStyle={{
        opacity: 1,
      }}
      focusStyle={{
        opacity: 1,
      }}
      borderWidth={1}
      borderColor="$overlay4"
      {...props}
    >
      <Input
        br="$3"
        width="100%"
        borderWidth="$0"
        height="100%"
        pr="$8"
        onChangeText={(value) => onSearchChange && onSearchChange(value)}
        onSubmitEditing={(event) => onSubmit && onSubmit(event.nativeEvent.text)}
        bg="$white"
        placeholder="Items, Brands, Life and more!"
        placeholderTextColor="$grey7"
        value={value}
        hoverStyle={{
          borderColor: '$overlay3',
          outlineWidth: 0,
        }}
        focusStyle={{
          borderColor: '$overlay3',
          backgroundColor: '$white',
          outlineWidth: 0,
        }}
        {...inputProps}
      />
      <XStack
        position="absolute"
        top="$0"
        right="$1"
        gap="$2"
        bottom="$0"
        alignItems="center"
        justifyContent="center"
        pr="$2.5"
      >
        {value.length > 0 && (
          <Button size="$2" theme="gray" onPress={clearInput} circular>
            <X size={18} />
          </Button>
        )}
        <Search size={24} />
      </XStack>
    </XStack>
  )
}

export const SearchBar = ({ onSearchChange, value }: SearchFieldProps & XStackProps) => {
  return (
    <>
      <SearchField
        display="none"
        $gtLg={{ display: 'block' }}
        onSearchChange={onSearchChange}
        value={value}
      />
      {/* <SearchButton $gtLg={{ display: 'none'}} /> */}
    </>
  )
}

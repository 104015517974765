import { SizeTokens, isWeb } from 'tamagui'
import { colors } from './colors'
import { Platform } from 'react-native'

type Shadow = {
  elevation: SizeTokens
  shadowOpacity: number
  shadowColor: string
  shadowRadius?: number
  elevationAndroid?: number
  shadowOffset?: {
    width: number
    height: number
  }
}

export const shadowStandard: Shadow = {
  elevation: 2,
  shadowOpacity: Platform.OS === 'android' ? 0.03 : 0.08,
  shadowColor: colors.black,
  shadowRadius: 8,
  shadowOffset: {
    width: -1,
    height: 2,
  },
  ...!isWeb && {
    elevationAndroid: 3,
  }
}

export const shadowSmall: Shadow = {
  elevation: 1,
  shadowOpacity: Platform.OS === 'android' ? 0.02 : 0.08,
  shadowColor: colors.black,
  shadowRadius: 4,
  shadowOffset: {
    width: -1,
    height: 2,
  },
  ...!isWeb && {
    elevationAndroid: 4,
  }
}

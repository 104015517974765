import { GetProps, TamaguiElement, XStack, styled } from 'tamagui'
import { shadowStandard } from '../../styles'
import { forwardRef } from 'react'

/**
 * Box is a primitive layout component useful for creating "Cards" or "Panels" of content.
 * It is a simple wrapper around the XStack component with some default styles applied.
 */

export const BoxWrapper = styled(XStack, {
  name: 'Box',
  ai: 'center',
  bg: '$white',
  p: '$3.5',
  borderRadius: '$2',
  variants: {
    shadow: {
      true: {
        ...shadowStandard,
      },
    },
    pressable: {
      true: {
        tag: 'button',
        cursor: 'pointer',
        hoverStyle: {
          opacity: 0.8,
        },
        focusStyle: {
          opacity: 0.7,
        },
        pressStyle: {
          opacity: 0.75,
        },
      },
    } as const,
  },
  defaultVariants: {
    shadow: true,
  },
})

type BoxWrapperProps = GetProps<typeof BoxWrapper>

export type BoxProps = {
  children?: React.ReactNode
} & BoxWrapperProps

export const Box = forwardRef<TamaguiElement, BoxProps>((props, ref) => {
  const { children, ...rest } = props

  return (
    <BoxWrapper {...rest} ref={ref}>
      {children}
    </BoxWrapper>
  )
})

import { QueryHookOptions, gql } from '@apollo/client'
import { NewCommentBlockFragment, NotificationBlock } from '../blocks'
import { usePersistedCacheQuery } from 'app/hooks'
import { NewGroupMemberRequestBlockFragment } from '../blocks/new-group-member-request-block'

const query = gql`
  query fetchActivityNotifications($page: Int!, $limit: Int!, $sort: String!) {
    ActivityNotifications(page: $page, limit: $limit, sort: $sort) {
      totalDocs
      docs {
        id
        notification {
          ${NewCommentBlockFragment.partialFragment}
          ${NewGroupMemberRequestBlockFragment.partialFragment}
        }
        createdAt
      }
    }
  }
`

export enum ActivityNotificationSort {
  CreatedAtAsc = 'createdAt',
  CreatedAtDesc = '-createdAt',
}

export interface ActivityNotificationsQueryInput {
  page: number
  limit: number
  sort: ActivityNotificationSort
}

export interface ActivityNotificationsQueryOutput {
  ActivityNotifications: {
    totalDocs: number
    docs: {
      id: string
      notification: NotificationBlock[]
      createdAt: string
    }[]
  }
}

const defaultVariables = {
  page: 1,
  limit: 10,
  sort: ActivityNotificationSort.CreatedAtDesc,
}

export const useActivityNotificationsQuery = (options: QueryHookOptions<ActivityNotificationsQueryOutput, ActivityNotificationsQueryInput>) => usePersistedCacheQuery<ActivityNotificationsQueryOutput, ActivityNotificationsQueryInput>(query, options)

export const activityNotificationsQuery = { query, defaultVariables }

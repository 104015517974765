import { NotificationBlock } from './blocks.types'
import { getNotificationBlockMappings } from './block-mappings'

export interface NotificationBlockRendererProps {
  block: NotificationBlock
  createdAt: string
}

/**
 * This will allow rendering each individual notification in the FlatList
 */
export const NotificationBlockRenderer = (props: NotificationBlockRendererProps) => {
  const componentsMap = getNotificationBlockMappings()

  const createBlock = (block: NotificationBlock) => {
    const extraProps = {
      key: `${block.id}`,
      id: block.id,
      createdAt: props.createdAt,
    }

    const { Component } = componentsMap[block.blockType] || {}

    if (!Component) {
      return null
    }

    return <Component {...block} {...extraProps} />
  }

  return createBlock(props.block)
}

import { Box, SizableText, SmartLink, XStack } from '@my/ui'
import { formatTimeToNow } from 'app/utils/format-time-to-now'
import { MockLikesBlock } from './mock-likes.block'
import { NotificationUserImage } from '../../components'

export const MockLikesComponent = (block: MockLikesBlock) => {
  const formattedTime = formatTimeToNow(block.createdAt, false)

  return (
    <SmartLink
      Component={Box}
      href={block.href}
      flex={1}
      gap="$2"
      ai="center"
      jc="space-between"
      shadow={false}
      pressable
    >
      <XStack flex={1} ai="center" gap="$2">
        <NotificationUserImage images={block.profileImages} />
        <XStack flex={1} gap="$1.5">
          <SizableText size="$2" fow="600">
            {block.name}
          </SizableText>
          <SizableText flex={1} numberOfLines={1} color="$overlay10" size="$2" fow="600">
            {block.description}
          </SizableText>
        </XStack>
      </XStack>

      <XStack flexShrink={0} ai="center" gap="$2">
        <SizableText size="$2" color="$overlay8">
          {formattedTime}
        </SizableText>
      </XStack>
    </SmartLink>
  )
}

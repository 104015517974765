import React, { useEffect } from 'react'
import { BackHandler, Dimensions } from 'react-native'
import {
  Button,
  ButtonProps,
  YStack,
  SizableText,
  SmartLink,
  isWeb,
  TextLink,
  XStack,
  Separator,
  ScrollView,
  Spacer,
  useSafeArea,
  AnimatePresence,
} from '@my/ui'
import { ChevronRight, Gift, LogOut, Share2 } from '@tamagui/lucide-icons'
import config from 'app/config'
import { useRouter } from 'solito/router'
import { ShareModal } from 'app/features/sharing/components'
import { StudentVerificationInfo, UserAvatar } from 'app/features/user/components'
import { useAuth } from 'app/provider/auth'
import { BlurBackground } from './blur-background'
import { Logo } from '../../logo'
import { primarySideMenuLinks, secondarySideMenuLinks } from './side-menu-links'

import { useCurrentRoute } from 'app/hooks'

import { footerLinks } from '../footer'

const SidebarButton = (props: ButtonProps) => {
  return <Button fontSize="$5" plain px="$2" {...props} />
}

type SideMenuProps = {
  isOpen?: boolean
  setOpen: (isOpen: boolean) => void
}

export const SideMenu = ({ isOpen, setOpen }: SideMenuProps) => {
  const { user, logOut } = useAuth()
  const insets = useSafeArea()
  const { replace } = useRouter()
  const { height } = isWeb ? { height: '100%' } : Dimensions.get('window')
  const shareLinkUrl = config.siteUrl

  const {
    getCurrentRoute,
    setCurrentRoute,
  } = useCurrentRoute();

  const isLoggedIn = !!user?.id

  const toggleMenuHandle = () => {
    setOpen(!isOpen)
  }

  const logOutHandle = () => {
    setOpen(false)
    logOut()
  }

  const handleLoginClick = () => {
    const currentRoute = getCurrentRoute()
    setCurrentRoute(currentRoute)

    replace('/log-in')
  }

  useEffect(() => {
    const backAction = () => {
      setOpen(false)
      return isOpen
    }

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, [])

  return (
    <>
      {isOpen && (
        <BlurBackground
          key="backdrop"
          pos="absolute"
          h={height}
          w="100%"
          zi="$4"
          fullscreen
          opacity={1}
          onPress={toggleMenuHandle}
          enterStyle={{
            opacity: 0,
          }}
          exitStyle={{
            opacity: 0,
          }}
          animation="default"
        />
      )}
      <AnimatePresence>
        {isOpen && (
          <YStack
            key="sideMenu"
            pos={isWeb ? ('fixed' as 'absolute') : 'absolute'}
            maxWidth={350}
            w="85%"
            x={0}
            flexGrow={1}
            zIndex="$5"
            top="$0"
            right="$0"
            height={height}
            animation="default"
            $gtXs={{
              w: '100%'
            }}
            enterStyle={{
              x: 1000,
            }}
            exitStyle={{
              x: 1000,
            }}
          >
            <ScrollView
              flexGrow={1}
              w="100%"
              bc="$background"
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: insets.bottom,
              }}
            >
              <YStack flex={1} p="$3.5" gap="$3.5">
                {!isLoggedIn && (
                  <YStack ai="center" jc="center" gap="$3">
                    <Logo width={90} />
                    <SizableText textAlign="center" size="$5" fow="600">
                      Get the best student discounts, freebies & giveaways!
                    </SizableText>
                    <YStack w="100%" flex={1} gap="$2">
                      <SmartLink Component={Button} w="100%" theme="black" href="/sign-up">
                        Join Now!
                      </SmartLink>
                      <SizableText size="$1" textAlign="center">
                        Already have an account?{' '}
                        <SizableText style={{textDecoration: 'underline', cursor: 'pointer'}} size="$1" fow="600" onPress={handleLoginClick}>
                          Log in
                        </SizableText>
                      </SizableText>
                    </YStack>
                  </YStack>
                )}

                {isLoggedIn && (
                  <SmartLink
                    Component={XStack}
                    onPress={toggleMenuHandle}
                    href="/profile"
                    minHeight={100}
                    ai="center"
                    gap="$3.5"
                    w="100%"
                    br="$4"
                    p="$3.5"
                    bg="$primary4"
                  >
                    <UserAvatar width={64} height={64} src={user.avatar?.medium} rounded />

                    <YStack flex={1} py="$1">
                      <XStack flex={1} w="100%" gap="$2" jc="space-between" ai="center">
                        <SizableText size="$5" numberOfLines={1} fow="600">
                          {user.fullName}
                        </SizableText>
                        <SizableText
                          ta='right'
                          size="$1"
                          fow="500"
                          textDecorationLine="none"
                          color="$overlay11"
                          hoverStyle={{
                            textDecorationLine: 'underline',
                            opacity: 0.8,
                          }}
                        >
                          View Profile
                        </SizableText>
                      </XStack>

                      <YStack flex={1}>
                        <SizableText numberOfLines={1} color="$overlay10" size="$1" fow="500">
                          {user.subjectOfStudy?.name}
                        </SizableText>
                        {(user.university?.name || user?.yearOfStudy) && (
                          <XStack gap="$2" flex={1}>
                            {user.university?.name && (
                              <SizableText
                                flex={1}
                                numberOfLines={1}
                                color="$overlay10"
                                size="$1"
                                fow="500"
                              >
                                {user.university.name}
                              </SizableText>
                            )}
                            {!!Number(user?.yearOfStudy) && (
                              <SizableText color="$overlay10" size="$1" fow="500">
                                {user.yearOfStudy}
                              </SizableText>
                            )}
                          </XStack>
                        )}
                      </YStack>
                    </YStack>
                  </SmartLink>
                )}
                <YStack gap="$4">
                  <ShareModal
                    href={shareLinkUrl}
                    subject="Check out Student Saviour"
                    trigger={
                      <Button
                        plain
                        variant="outlined"
                        br="$3"
                        fontWeight="500"
                        px="$2"
                        icon={<Share2 size={20} />}
                      >
                        Share Student Saviour
                      </Button>
                    }
                  />

                  <SmartLink
                    theme="primary"
                    href="/competition"
                    Component={Button}
                    icon={<Gift size={20} />}
                    fontWeight="500"
                    onPress={toggleMenuHandle}
                  >
                    Giveaway
                  </SmartLink>
                  {user && (
                    <StudentVerificationInfo
                      universityEmail={user.universityEmail}
                      universityEmailVerified={user.universityEmailVerified}
                    />
                  )}
                </YStack>
                <YStack gap="$2">
                  {primarySideMenuLinks.map(({ label, Icon, href }) => (
                    <SmartLink
                      Component={SidebarButton}
                      jc="flex-start"
                      href={href}
                      icon={<Icon size={24} />}
                      key={label}
                      onPress={toggleMenuHandle}
                    >
                      {label}
                    </SmartLink>
                  ))}
                </YStack>

                {isLoggedIn && (
                  <>
                    <Separator borderColor="$overlay6" />
                    <YStack gap="$2">
                      <SmartLink
                        Component={SidebarButton}
                        plain
                        jc="space-between"
                        href="/profile/settings"
                        iconAfter={<ChevronRight size={24} />}
                        onPress={toggleMenuHandle}
                        fontWeight="600"
                      >
                        Settings
                      </SmartLink>
                    </YStack>
                  </>
                )}

                <Separator bg="$overlay6" borderColor="$overlay6" />

                {isLoggedIn && (
                  <SidebarButton jc="flex-start" icon={<LogOut size={24} />} onPress={logOutHandle}>
                    Log out
                  </SidebarButton>
                )}

                {!isWeb && (
                  <>
                    <XStack mt="auto" px="$2" rowGap="$3.5" columnGap="$4" flexWrap="wrap">
                      {[...footerLinks, ...secondarySideMenuLinks].map((item) => (
                        <TextLink
                          p="$0"
                          color="$overlay11"
                          href={item.href}
                          key={item.href}
                          size="$3"
                          onPress={toggleMenuHandle}
                        >
                          {item.label}
                        </TextLink>
                      ))}
                    </XStack>
                    <YStack mt="$2" px="$2" gap="$2">
                      <SizableText size="$3">
                        Student Saviour &copy; Copyright {new Date().getFullYear()}.
                      </SizableText>
                      <SizableText size="$3">All Rights Reserved.</SizableText>
                    </YStack>
                    <Spacer size="$10" />
                  </>
                )}
                {isWeb && (
                  <YStack flex={1} justifyContent="flex-end" gap="$12">
                    <XStack gap="$4">
                      {secondarySideMenuLinks.map((item) => (
                        <TextLink
                          fontSize="$3"
                          color="$overlay11"
                          fow="500"
                          key={item.href}
                          href={item.href}
                        >
                          {item.label}
                        </TextLink>
                      ))}
                    </XStack>
                  </YStack>
                )}
              </YStack>
            </ScrollView>
          </YStack>
        )}
      </AnimatePresence>
    </>
  )
}

import { YStack, YStackProps } from '@my/ui'
import { SolitoImage } from 'solito/image'
import { SolitoImageProps } from 'solito/build/image/image.types'
import avatarDefault from 'app/assets/images/user-avatar-default.png'

export interface AvatarProps {
  src?: any
  alt?: string
  rounded?: boolean
  imageProps?: SolitoImageProps
}

export const UserAvatar = ({
  src,
  alt,
  width = 100,
  height = 100,
  rounded = true,
  imageProps,
  ...props
}: AvatarProps & YStackProps) => {
  return (
    <YStack
      borderRadius={rounded ? 100_000 : '$3'}
      bw="$0"
      w={width}
      h={height}
      overflow="hidden"
      {...props}
    >
      <SolitoImage contentFit="fill" src={src || avatarDefault} alt={alt || 'User avatar'} fill />
    </YStack>
  )
}

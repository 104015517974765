export const footerLinks = [
  {
    label: 'Terms & Conditions',
    href: '/terms-conditions',
  },
  {
    label: 'Advertise',
    href: 'https://partners.studentsaviour.com',
  },
  {
    label: 'Ad Disclosure',
    href: '/ad-disclosure',
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    label: 'Cookie Policy',
    href: '/cookie-policy',
  },
]

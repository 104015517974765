import { NotificationBlock } from '../blocks.types'

export const MockDiscountBlockType = 'mockDiscount'

export interface MockDiscountBlock extends NotificationBlock {
  blockType: typeof MockDiscountBlockType
  name: string
  description: string
  image: string
  href: string
}

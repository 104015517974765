import { gql } from '@apollo/client'
import { BlockFragment } from 'app/features/shared/blocks'
import { NotificationBlock } from '../blocks.types'

export const NewGroupMemberRequestBlockFragment: BlockFragment = {
  name: 'newGroupMemberRequest',
  fragment: gql`
    fragment newGroupMemberRequest on NewGroupMemberRequest {
      id
      blockName
      blockType
      groupMemberRequest {
        id
      }
      group {
        id
        name
      }
      user {
        id
        fullName
        avatar {
          id
          sizes {
            small {
              url
            }
            medium {
              url
            }
          }
        }
      }
    }
  `,
  partialFragment: `
    ...on NewGroupMemberRequest {
      id
      blockName
      blockType
      groupMemberRequest {
        id
      }
      group {
        id
        name
      }
      user {
        id
        fullName
        avatar {
          id
          sizes {
            small {
              url
            }
            medium {
              url
            }
          }
        }
      }
    }
  `,
}

export const NewGroupMemberRequestBlockType = 'newGroupMemberRequest'

export interface NewGroupMemberRequestBlock extends NotificationBlock {
  blockType: typeof NewGroupMemberRequestBlockType
  groupMemberRequest: {
    id: string
  }
  group: {
    id: string
    name: string
  }
  user: {
    id: string
    fullName: string
    avatar?: {
      id: string
      sizes: {
        small: {
          url: string
        }
        medium: {
          url: string
        }
      }
    }
  }
}

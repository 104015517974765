import {
  Container,
  ContainerProps,
  shadowStandard,
  SizableText,
  SmartLink,
  Stack,
  StackProps,
  XStack,
  YStack,
} from '@my/ui'

import config from 'app/config'
import { SolitoImage } from 'solito/image'
import { footerLinks } from './footer-links'
import AppStoreImage from 'app/assets/images/Appstore.svg'
import GooglePlayImage from 'app/assets/images/Playstore.svg'
import { shareContentLinks } from 'app/features/sharing/components'

interface FooterButtonProps {
  children: React.ReactNode
  href: string
}

const FooterButton = ({ children, href, ...props }: FooterButtonProps & StackProps) => (
  <SmartLink
    Component={Stack}
    href={href}
    target="_blank"
    flex={1}
    ai="center"
    jc="center"
    fd="column"
    overflow="hidden"
    br="$3"
    bc="$white"
    bw={1}
    boc="$overlay4"
    focusStyle={{
      ...shadowStandard,
    }}
    hoverStyle={{
      ...shadowStandard,
    }}
    {...props}
  >
    {children}
  </SmartLink>
)

export const Footer = (props: ContainerProps) => {
  const siteUrl = config.siteUrl
  return (
    <Container backgroundColor="$white" borderTopWidth={-2} borderColor="$overlay2" py="$3">
      <Container size="xl" gutter>
        <Container size="base" tag="footer" gutter py="$3" ai="flex-start" {...props}>
          <YStack w="100%" alignItems="center" gap="$4">
            <XStack
              alignItems="center"
              justifyContent="center"
              flex={1}
              rowGap="$1"
              columnGap="$4"
              flexWrap="wrap"
            >
              {footerLinks.map((item, index) => (
                <SmartLink
                  py="$1"
                  Component={SizableText}
                  href={item.href}
                  color="$overlay12"
                  size="$3"
                  fow="500"
                  key={`footer-link-${index}`}
                  hoverStyle={{
                    textDecorationLine: 'underline',
                  }}
                  focusStyle={{
                    textDecorationLine: 'underline',
                  }}
                  pressStyle={{
                    textDecorationLine: 'underline',
                  }}
                >
                  {item.label}
                </SmartLink>
              ))}
            </XStack>
          </YStack>
        </Container>

        <YStack
          w="100%"
          mt="$3"
          borderTopWidth={1}
          borderTopColor="$overlay2"
          pt="$3"
          alignItems="center"
          $gtMd={{
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
          }}
        >
          <YStack
            flex={1}
            gap="$3"
            mb="$4"
            $gtMd={{ flexDirection: 'row', mb: '$0', justifyContent: 'flex-end' }}
          >
            <XStack gap="$3" alignItems="center" justifyContent="center">
              {shareContentLinks(siteUrl, 'Student Saviour').map((item) => {
                const Icon = item.icon
                return (
                  <FooterButton
                    aria-label={`Share via ${item.label}`}
                    key={item.href}
                    href={item.href}
                    h={44}
                    w={44}
                    bw={1}
                  >
                    <Icon size={20} />
                  </FooterButton>
                )
              })}
            </XStack>
            <XStack gap="$3">
              <FooterButton
                href={`https://apps.apple.com/us/app/student-saviour/id6450699794`}
                aspectRatio={148 / 44}
                h={44}
                w={148}
              >
                <SolitoImage alt="app store" width={148} src={AppStoreImage} />
              </FooterButton>
              <FooterButton
                href={`https://play.google.com/store/apps/details?id=com.studentsaviour.app&pli=1`}
                aspectRatio={148 / 44}
                h={44}
                w={148}
              >
                <SolitoImage alt="google play" width={148} src={GooglePlayImage} />
              </FooterButton>
            </XStack>
          </YStack>
          <SizableText size="$3" fow="500" color="$overlay11">
            Student Saviour &copy; Copyright {new Date().getFullYear()}. All Rights Reserved.
          </SizableText>
        </YStack>
      </Container>
    </Container>
  )
}

import { Container, ContainerProps, SizableText, Stack } from '@my/ui'
import { useRouter } from 'solito/router'
import { ChevronLeft } from '@tamagui/lucide-icons'

export const IFRAME_NAV_HEIGHT = 48

interface IFrameNavigationProps {}

export const IFrameNavigation = ({ ...props }: IFrameNavigationProps & ContainerProps) => {
  const router = useRouter()

  return (
    <>
      <Container
        gutter
        bg="$white"
        fd="row"
        ai="center"
        h={IFRAME_NAV_HEIGHT}
        zIndex="$4"
        size="xl"
        {...props}
      >
        <Stack
          fd="row"
          ai="center"
          tag="button"
          gap="$2"
          onPress={() => router.back()}
          aria-label="Go back"
          cur="pointer"
        >
          <ChevronLeft size={24} />
          <SizableText size="$4">Back</SizableText>
        </Stack>
      </Container>
      <Stack opacity={0} h={IFRAME_NAV_HEIGHT} />
    </>
  )
}

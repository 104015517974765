import { Heart, HeartHandshake, Percent, Search, Star, Users } from '@tamagui/lucide-icons'

export const primarySideMenuLinks = [
  {
    label: 'Search',
    Icon: Search,
    href: '/search',
  },
  {
    label: 'Discounts',
    Icon: Percent,
    href: '/student-discount',
  },
  {
    label: 'Favourites',
    Icon: Heart,
    href: '/favourites',
  },
  {
    label: 'Chat',
    Icon: Users,
    href: '/groups/feed',
  },
  {
    label: 'Life',
    Icon: HeartHandshake,
    href: '/life',
  },
  {
    label: 'Freshers',
    Icon: Star,
    href: '/freshers',
  },
]

export const secondarySideMenuLinks = [
  {
    label: 'Support',
    href: '/support',
  },
  {
    label: 'Write For Us',
    href: '/write-for-us',
  },
  {
    label: 'Contact',
    href: '/contact-us',
  },
]

import {
  colors,
  shadowStandard,
  SizableText,
  Stack,
  XStack,
  YStack,
  Button,
  SmartLink,
} from '@my/ui'
import { CheckCircle, CircleSlash2 } from '@tamagui/lucide-icons'
import format from 'date-fns/format'
import addYears from 'date-fns/addYears'
import { useAuth } from 'app/provider/auth'

interface StudentVerificationInfoProps {
  universityEmailVerified?: boolean
  universityEmail?: string
}

export const StudentVerificationInfo = ({
  universityEmailVerified,
  universityEmail,
}: StudentVerificationInfoProps) => {
  const { user } = useAuth()
  return (
    <YStack
      borderRadius="$2"
      padding="$4"
      width="100%"
      justifyContent="space-between"
      {...shadowStandard}
      backgroundColor="$white"
      gap="$3"
    >
      <YStack flex={1} width="100%" gap="$2">
        <XStack alignItems="center" gap="$3">
          {universityEmailVerified ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              {...shadowStandard}
              h={40}
              w={40}
              borderRadius={10000}
              backgroundColor="$white"
            >
              <CheckCircle color={colors.primary} size={26} />
            </Stack>
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              {...shadowStandard}
              h={40}
              w={40}
              borderRadius={10000}
              backgroundColor="$white"
            >
              <CircleSlash2 color={colors.red11} size={26} />
            </Stack>
          )}

          {universityEmailVerified ? (
            <YStack>
              <SizableText size="$4" fow="600">
                You’re Verified!
              </SizableText>
              {user?.createdAt && (
                <SizableText color="$grey6" size="$3">
                  Expires on {format(addYears(new Date(user.createdAt), 1), 'dd/MM/yyyy')}
                </SizableText>
              )}
            </YStack>
          ) : (
            <SizableText size="$4" fow="600">
              You need to verify your Student Email Address
            </SizableText>
          )}
        </XStack>
        {universityEmail && (
          <SizableText my="$2" size="$3" color="$grey6">
            Student Email: {universityEmail}
          </SizableText>
        )}
      </YStack>

      {!universityEmailVerified && (
        <XStack>
          <SmartLink
            Component={Button}
            theme="black"
            size="$4"
            fontWeight="500"
            href="/onboarding/student/university-email"
          >
            Verify Now
          </SmartLink>
        </XStack>
      )}
    </YStack>
  )
}

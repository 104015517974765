import { Box, SizableText, SmartLink, XStack } from '@my/ui'
import { formatTimeToNow } from 'app/utils/format-time-to-now'
import { NewGroupMemberRequestBlock } from './new-group-member-request.block'
import { NotificationUserImage } from '../../components'

export const NewGroupMemberRequestComponent = (block: NewGroupMemberRequestBlock) => {
  const formattedTime = formatTimeToNow(block.createdAt, false)
  const queryParams = new URLSearchParams({
    requestId: block.groupMemberRequest.id,
    name: block.user.fullName,
  });

  return (
    <SmartLink
      Component={Box}
      href={`/groups/${block.group.id}/admin/requested-users?${queryParams.toString()}`}
      flex={1}
      gap="$2"
      ai="center"
      jc="space-between"
      shadow={false}
      pressable
    >
      <XStack flex={1} ai="center" gap="$2">
        <NotificationUserImage images={[block.user?.avatar?.sizes?.small?.url || '']} />
        <XStack flex={1} gap="$1.5">
          <SizableText size="$2" fow="600">
            {block.user.fullName}
          </SizableText>
          <SizableText flex={1} numberOfLines={1} color="$overlay10" size="$2" fow="600">
            requested to join {block.group.name}
          </SizableText>
        </XStack>
      </XStack>

      <XStack flexShrink={0} ai="center" gap="$2">
        <SizableText size="$2" color="$overlay8">
          {formattedTime}
        </SizableText>
      </XStack>
    </SmartLink>
  )
}

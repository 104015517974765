import {
  Button,
  SizableText,
  SmartLink,
  Stack,
  XStack,
  YStack,
  shadowStandard,
  styled,
} from '@my/ui'
import { Copy, Check } from '@tamagui/lucide-icons'
import { copyToClipboard } from 'app/lib/copy-to-clipboard'
import { useState } from 'react'
import { ShareContentProps } from './share-content.props'
import { shareContentLinks } from './share-content-links'

const CopyField = styled(XStack, {
  position: 'relative',
  ai: 'center',
  py: '$4',
  px: '$3',
  bg: '$white',
  flexShrink: 1,
  borderWidth: 1,
  borderRadius: '$3',
  jc: 'space-between',
  maxWidth: 500,
  cursor: 'pointer',
  borderColor: '$overlay8',
})

export const ShareContent = (props: ShareContentProps) => {
  const [copied, setCopied] = useState(false)

  const handleSelect = () => {
    copyToClipboard(props.href)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <YStack w="100%" gap="$3" bg="$secondary9">
      <XStack
        maxWidth={300}
        mx="auto"
        w="100%"
        alignItems="center"
        jc="center"
        gap="$4"
        py="$2"
        className="overflow-x-auto"
      >
        {shareContentLinks(props.href, props.subject).map((item) => {
          const Icon = item.icon
          return (
            <SmartLink
              key={item.href}
              Component={YStack}
              gap="$2"
              ai="center"
              jc="center"
              flex={1}
              href={`${item.href}`}
              target="_blank"
              hoverStyle={{ opacity: 0.7 }}
              pressStyle={{ opacity: 0.7 }}
              focusStyle={{ opacity: 0.7 }}
            >
              <Stack
                fd="column"
                ai="center"
                jc="center"
                gap="$1"
                w={56}
                h={56}
                borderRadius={10000}
                bc="$white"
                {...shadowStandard}
              >
                <Icon size={20} />
              </Stack>

              <SizableText color="$overlay10" size="$4" ta="center">
                {item.label}
              </SizableText>
            </SmartLink>
          )
        })}
      </XStack>
      <XStack alignItems="center" justifyContent="center">
        <CopyField
          aria-label="Copy URL"
          tag="button"
          onPress={handleSelect}
          hoverStyle={{ opacity: 0.7 }}
          pressStyle={{ opacity: 0.7 }}
          focusStyle={{ opacity: 0.7 }}
        >
          <SizableText
            id="share-url"
            className="overflow-x-auto hide-scrollbar"
            color="$black"
            size="$3"
            whiteSpace="nowrap"
            userSelect="text"
            fow="600"
          >
            {props.href}
          </SizableText>
        </CopyField>
        <Button theme="primary" ml="$2" w={55} h={55} onPress={handleSelect}>
          {copied ? <Check /> : <Copy fontWeight="600" />}
        </Button>
      </XStack>
    </YStack>
  )
}

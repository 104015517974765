import { Stack, YStack } from '@my/ui'
import { UserAvatar } from 'app/features/user/components'

interface NotificationUserImageProps {
  images: string[]
}

export const NotificationUserImage = ({ images }: NotificationUserImageProps) => {
  if (images.length === 1) {
    return (
      <Stack overflow="hidden" w={32} h={32} br="$3">
        <UserAvatar width={32} height={32} src={images[0]} alt="" rounded={false} />
      </Stack>
    )
  }

  if (images.length > 1) {
    return (
      <YStack pos="relative" overflow="hidden" w={32} h={32} br="$3">
        <Stack pos="absolute" top={0} left={0} overflow="hidden" w={24} h={24} br="$3">
          <UserAvatar width={24} height={24} src={images[0]} alt="" rounded={false} />
        </Stack>
        <Stack
          zIndex="$1"
          pos="absolute"
          overflow="hidden"
          bottom={0}
          right={0}
          w={24}
          h={24}
          br="$3"
        >
          <UserAvatar width={24} height={24} src={images[1]} alt="" rounded={false} />
        </Stack>
      </YStack>
    )
  }

  return null
}

import { NotificationBlock } from '../blocks.types'

export const MockLikesBlockType = 'mockLikes'

export interface MockLikesBlock extends NotificationBlock {
  blockType: typeof MockLikesBlockType
  id: string
  name: string
  description: string
  profileImages: string[]
  href: string
}

import { Stack, StackProps } from 'tamagui'
import { SolitoImage } from 'solito/image'
import logoImg from 'app/assets/images/logo.svg'
import { forwardRef } from 'react'
import { View } from 'react-native'

type LogoProps = {
  width: number
} & StackProps

export const Logo = forwardRef<View, LogoProps>(({ width, ...props }, ref) => {
  return (
    <Stack ref={ref} width={width} aspectRatio={9 / 5} {...props}>
      <SolitoImage fill src={logoImg} alt="Student Saviour" />
    </Stack>
  )
})

import { BlockToComponentMap } from 'app/features/shared/blocks'
import { MockDiscountBlockType, MockDiscountComponent } from './mock-discount-block'
import { MockLikesBlockType, MockLikesComponent } from './mock-likes-block'
import { NewCommentBlockType, NewCommentComponent } from './new-comment-block'
import { NewGroupMemberRequestBlockType, NewGroupMemberRequestComponent } from './new-group-member-request-block'

export const getNotificationBlockMappings = (): BlockToComponentMap => ({
  [MockDiscountBlockType]: { Component: MockDiscountComponent },
  [MockLikesBlockType]: { Component: MockLikesComponent },
  [NewCommentBlockType]: { Component: NewCommentComponent },
  [NewGroupMemberRequestBlockType]: { Component: NewGroupMemberRequestComponent },
})

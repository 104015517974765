import { formatDistanceToNowStrict, isValid } from 'date-fns'
import locale from 'date-fns/locale/en-US'

const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
}

/**
 * Return the distance between the given date and now in words.
 */
export const formatTimeToNow = (date: string, addSuffix = true) => {
  const dateObj = new Date(date)
  if (!isValid(dateObj)) return ''

  const formattedTime = formatDistanceToNowStrict(dateObj, {
    locale: {
      ...locale,
      formatDistance,
    },
    addSuffix
  })

  return formattedTime
}

function formatDistance(token, count, options) {
  options = options || {}

  const result = formatDistanceLocale[token].replace('{{count}}', count)

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result
    } else {
      return result + ' ago'
    }
  }

  return result
}
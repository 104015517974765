import { SafeAreaView, StyleProp, ViewStyle } from 'react-native'
import { Button, Container, SearchField, SizableText, SmartLink, XStack, colors } from '@my/ui'
import React, { useState } from 'react'
import { AlignJustify, Bell, Search, User } from '@tamagui/lucide-icons'
import { SideMenu } from '../side-menu'
import { Logo } from '../../logo'
import { useNextRouter } from 'app/hooks/use-next-router'
import { NotificationsDropdown } from 'app/features/notifications/containers/notifications-dropdown'
import { useAuth } from 'app/provider/auth'
import { StructuredMeta } from 'app/components/meta'
import config from 'app/config'

export const NAV_HEIGHT = 60
export const NAV_HEIGHT_SM = 82

interface NavigationProps {
  style?: StyleProp<ViewStyle>
}

const NavigationItem = ({ label, href, isActive }) => {
  return (
    <SmartLink
      Component={Button}
      href={href}
      size="$5"
      h={56}
      px="$3"
      backgroundColor={isActive ? '$white' : '$primary'}
      borderWidth="$0"
      borderBottomLeftRadius="$0"
      borderBottomRightRadius="$0"
      hoverStyle={{
        opacity: 0.9,
        bg: '$white',
      }}
      focusStyle={{
        outlineWidth: 0,
        opacity: 0.8,
        bg: '$white',
      }}
      $gtLg={{
        px: '$5',
      }}
    >
      <SizableText
        size="$3"
        fow="500"
        $gtSm={{
          size: '$5',
          fow: '500',
        }}
        $gtLg={{
          size: '$6',
          fow: '500',
        }}
      >
        {label}
      </SizableText>
    </SmartLink>
  )
}

export const Navigation = ({ style }: NavigationProps) => {
  const { user } = useAuth()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { pathname, asPath } = useNextRouter()
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const currentPath = pathname.split('/')?.[1]
  const isHome = pathname === '/'

  const loggedIn = !!user?.id

  const handleEnter = () => {
    //! This needs to be used so that we also refresh the page
    window.location.href =
      searchTerm.trim().length > 0 ? `/search?query=${encodeURIComponent(searchTerm)}` : '/search'
  }

  return (
    <>
      <StructuredMeta
        type="siteLinksSearchBox"
        url={config.siteUrl}
        potentialActions={[
          {
            target: `${config.siteUrl}/search?q`,
            queryInput: 'search_term_string',
          }
        ]}
      />
      <SafeAreaView style={style}>
        <XStack w="100%" bg="$primary">
          <Container
            gutter
            fd="row"
            jc="space-between"
            ai="center"
            h={NAV_HEIGHT}
            py="$2"
            zIndex="$4"
            $gtSm={{
              h: NAV_HEIGHT_SM,
            }}
            size="xl"
          >
            <XStack jc="flex-start" flexBasis="15%">
              <SmartLink
                Component={Logo}
                href="/"
                jc="center"
                width={90}
                maxWidth={71}
                $gtSm={{ maxWidth: 90 }}
              />
            </XStack>

            <Container
              gutter
              flexShrink={1}
              top={13}
              ai="flex-end"
              fd="row"
              size="base"
              gap="$3"
              $gtLg={{
                pos: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
              }}
              $md={{
                display: 'none',
              }}
            >
              <NavigationItem
                label="Discounts"
                href="/student-discount"
                isActive={['student-discount', 'discounts', 'offers'].includes(currentPath || '')}
              />
              <NavigationItem
                label="Chat"
                href="/groups/feed"
                isActive={currentPath === 'groups'}
              />
              <NavigationItem label="Life" href="/life" isActive={currentPath === 'life'} />
              <NavigationItem
                label="Freshers"
                href="/freshers"
                isActive={currentPath === 'freshers'}
              />
              <NavigationItem
                label="Giveaway"
                href="/competition"
                isActive={['/competition', '/competition-entry'].includes(asPath || '')}
              />
            </Container>
            <XStack jc="flex-end">
              <SearchField
                display="none"
                $gtLg={{ display: 'flex' }}
                opacity={0.8}
                value={searchTerm}
                inputProps={{
                  placeholder: 'Items, Brands, Life and more!',
                }}
                onSearchChange={setSearchTerm}
                onSubmit={handleEnter}
              />

              <SmartLink
                Component={Button}
                href="/search"
                aria-label="Search"
                theme="white"
                size="$3"
                ml="$2"
                h={44}
                w={44}
                o={0.8}
                icon={<Search size={24} color={colors.black} />}
                $gtSm={{
                  h: 50,
                  w: 50,
                }}
                $gtLg={{
                  display: 'none',
                }}
              />
              {loggedIn && (
                <SmartLink
                  Component={Button}
                  href="/profile"
                  aria-label="Profile"
                  size="$3"
                  theme="white"
                  o={0.8}
                  ml="$2"
                  h={44}
                  w={44}
                  icon={<User size={24} color={colors.black} />}
                  $gtSm={{
                    h: 50,
                    w: 50,
                  }}
                  $gtMd={{
                    display: 'none',
                  }}
                />
              )}

              {loggedIn && (
                <NotificationsDropdown
                  open={notificationsOpen}
                  onOpenChange={setNotificationsOpen}
                  contentProps={{
                    $sm: {
                      display: 'none',
                    },
                  }}
                  anchor={
                    <>
                      <Button
                        display="none"
                        theme="white"
                        o={0.8}
                        size="$3"
                        ml="$2"
                        h={44}
                        w={44}
                        aria-label="View notifications"
                        icon={<Bell size={24} color={colors.black} />}
                        onPress={() => setNotificationsOpen(!notificationsOpen)}
                        $gtSm={{
                          h: 50,
                          w: 50,
                          display: 'flex',
                        }}
                      />
                      <SmartLink
                        Component={Button}
                        href="/notifications"
                        aria-label="Go to notifications page"
                        size="$3"
                        theme="white"
                        o={0.8}
                        ml="$2"
                        h={44}
                        w={44}
                        icon={<Bell size={24} color={colors.black} />}
                        $gtSm={{
                          h: 50,
                          w: 50,
                          display: 'none',
                        }}
                      />
                    </>
                  }
                />
              )}

              <Button
                theme="white"
                o={0.8}
                size="$3"
                ml="$2"
                h={44}
                w={44}
                aria-label="Side Menu"
                icon={<AlignJustify size={24} color={colors.black} />}
                onPress={() => setSidebarOpen(true)}
                $gtSm={{
                  h: 50,
                  w: 50,
                }}
              />
            </XStack>
          </Container>
        </XStack>
      </SafeAreaView>
      <SideMenu isOpen={sidebarOpen} setOpen={setSidebarOpen} />
    </>
  )
}

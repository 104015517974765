import { gql } from '@apollo/client'
import { BlockFragment } from 'app/features/shared/blocks'
import { NotificationBlock } from '../blocks.types'

export const NewCommentBlockFragment: BlockFragment = {
  name: 'newComment',
  fragment: gql`
    fragment newComment on NewComment {
      id
      blockName
      blockType
      content
      comment {
        id
      }
      creator {
        id
        fullName
        avatar {
          id
          sizes {
            small {
              url
            }
            medium {
              url
            }
          }
        }
      }
      groupPost {
        id
      }
    }
  `,
  partialFragment: `
    ...on NewComment {
      id
      blockName
      blockType
      content
      comment {
        id
      }
      creator {
        id
        fullName
        avatar {
          id
          sizes {
            small {
              url
            }
            medium {
              url
            }
          }
        }
      }
      groupPost {
        id
      }
    }
  `,
}

export const NewCommentBlockType = 'newComment'

export interface NewCommentBlock extends NotificationBlock {
  blockType: typeof NewCommentBlockType
  content: string
  comment: {
    id: string
  }
  creator: {
    id: string
    fullName: string
    avatar?: {
      id: string
      sizes: {
        small: {
          url: string
        }
        medium: {
          url: string
        }
      }
    }
  }
  groupPost: {
    id: string
  }
}

import { LinkCore } from 'solito/link'
import { ComponentType } from 'react'

type SmartLinkProps<C extends ComponentType<any>> = {
  Component: C
  href: string
  shallow?: boolean
  children: React.ReactNode
} & React.ComponentProps<C>

const makeLinkSafe = (href: string) => {
  const prefixes = [
    'http://',
    'https://',
    'mailto:',
    'tel:',
    'ftp://',
    'ftps://',
    'sftp://',
    'ssh://',
    'file://',
  ]

  if (prefixes.some((prefix) => href.startsWith(prefix))) {
    return href
  }

  if (href.startsWith('/')) {
    return href
  }

  if (href.startsWith('#')) {
    return href
  }

  return `/${href}`
}

/**
 * Wrapper around LinkCore that allows to use any component as a link.
 * <SmartLink Component={Button} margin="$2" href="/myPage">Example</SmartLink>
 */
export const SmartLink = <C extends ComponentType<any>>({
  Component,
  href,
  children,
  shallow,
  ...props
}: SmartLinkProps<C>) => {
  return (
    <LinkCore
      Component={Component}
      href={makeLinkSafe(href)}
      shallow={shallow}
      componentProps={{ accessibilityRole: 'link', tag: 'a', ...props }}
    >
      {children}
    </LinkCore>
  )
}

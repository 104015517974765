import { QueryHookOptions, gql } from '@apollo/client'
import { usePersistedCacheQuery } from 'app/hooks'

export const notificationCountQuery = gql`
  query fetchActivityNotificationCount {
    ActivityNotifications(page: 1, limit: 1, where: { hasBeenRead: { not_equals: true } }) {
      totalDocs
      docs {
        id
      }
    }
  }
`

export interface ActivityNotificationCountQueryOutput {
  ActivityNotifications: {
    totalDocs: number
  }
}

export const useActivityNotificationCountQuery = (
  options: QueryHookOptions<ActivityNotificationCountQueryOutput>
) =>
  usePersistedCacheQuery<ActivityNotificationCountQueryOutput>(notificationCountQuery, {
    fetchPolicy: 'cache-and-network',
    ...options,
  })

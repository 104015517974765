import { DocumentNode, OperationVariables, QueryHookOptions, QueryResult, TypedDocumentNode, useQuery } from '@apollo/client'
import { isWeb } from '@my/ui'

export function usePersistedCacheQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: TypedDocumentNode<TData, TVariables> | DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const result = useQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: isWeb ? 'cache-first' : 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const isActuallyLoading =
    result.loading &&
    !result.client.readQuery({
      query,
      variables: options?.variables,
    })

  return { ...result, loading: isActuallyLoading }
}

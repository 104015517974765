import { ArticleJsonLd, BrandJsonLd, BreadcrumbJsonLd, FAQPageJsonLd, SiteLinksSearchBoxJsonLd, WebPageJsonLd } from 'next-seo'
import { StructuredMetaProps } from './structured-meta.props'
import config from 'app/config'

export const StructuredMeta = (props: StructuredMetaProps) => {
  switch (props.type) {
    case 'article':
      return (
        <ArticleJsonLd
          type="Article"
          url={props.url}
          title={props.title}
          images={props.image ? [props.image] : []}
          datePublished={props.date}
          authorName={[
            {
              name: 'Student Saviour',
              url: config.siteUrl,
            },
          ]}
          publisherName="Student Saviour"
          publisherLogo={`${config.siteUrl}/static/logo.png`}
          description={props.description}
        />
      )

      case 'brand':
        return (
          <BrandJsonLd
            type="Brand"
            id={props.id}
            slogan={props.slogan}
            logo={props.logo}
          />
        )

      case 'breadcrumb':
        return (
          <BreadcrumbJsonLd
            itemListElements={props.breadcrumbs.map((breadcrumb, index) => ({
              position: index + 1,
              name: breadcrumb.name,
              item: breadcrumb.url,
            }))}
          />
        )

      case 'faq':
        return (
          <FAQPageJsonLd
            mainEntity={props.faqs.map((faq) => ({
              questionName: faq.question,
              acceptedAnswerText: faq.answer,
            }))}
          />
        )

      case 'siteLinksSearchBox':
        return (
          <SiteLinksSearchBoxJsonLd
            url={props.url}
            potentialActions={props.potentialActions.map((action) => ({
              target: action.target,
              queryInput: action.queryInput,
            }))}
          />
        )

    default:
      return null
  }
}

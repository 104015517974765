import { gql, useMutation } from '@apollo/client'
import { notificationCountQuery } from '../queries'

const mutation = gql`
  mutation setNotificationRead($notificationId: String) {
    setNotificationRead(notificationId: $notificationId) {
      success
      message
    }
  }
`

export interface SetNotificationReadInput {
  notificationId?: string
}

export interface SetNotificationReadOutput {
  setNotificationRead: {
    success: boolean
    message: string
  }
}

export const useSetNotificationRead = () =>
  useMutation<SetNotificationReadOutput, SetNotificationReadInput>(mutation, {
    refetchQueries: [
      notificationCountQuery,
    ],
  });

import { GetProps, YStack, styled } from 'tamagui'

export const gutterSize = '$3.5'
export const gutterSizeTablet = '$5'

/**
 * Used for adding consistent max-width, padding, and centering to content.
 * If you don't need any of these, use `Stack` instead.
 */
const ContainerWrapper = styled(YStack, {
  w: '100%',
  fd: 'column',
  variants: {
    centered: {
      true: {
        mx: 'auto',
      },
    },
    gutter: {
      gtXs: {
        $gtXs: {
          px: gutterSize,
        },
        $gtMd: {
          px: gutterSizeTablet,
        },
      },
      gtSm: {
        $gtSm: {
          px: gutterSize,
        },
        $gtMd: {
          px: gutterSizeTablet,
        },
      },
      true: {
        px: gutterSize,
        $gtMd: {
          px: gutterSizeTablet,
        },
      },
    },
    spaceTop: {
      true: {
        pt: '$3.5',
        $gtMd: {
          pt: '$6',
        },
      },
    },
    spaceBottom: {
      true: {
        pb: '$7',
        $gtMd: {
          pb: '$13',
        },
      },
    },
    size: {
      xs: {
        maxWidth: 420,
        $gtMd: {
          maxWidth: 460,
        },
      },
      sm: {
        maxWidth: 600,
        $gtMd: {
          maxWidth: 640,
        },
      },
      md: {
        maxWidth: 760,
        $gtMd: {
          maxWidth: 800,
        },
      },
      base: {
        maxWidth: 900,
        $gtMd: {
          maxWidth: 940,
        },
      },
      lg: {
        maxWidth: 1200,
        $gtMd: {
          maxWidth: 1240,
        },
      },
      xl: {
        maxWidth: 1600,
      },
    },
  } as const,
  defaultVariants: {
    centered: true,
  },
})

export type ContainerProps = {
  children?: React.ReactNode
} & GetProps<typeof ContainerWrapper>

export const Container = ({ children, ...props }: ContainerProps) => {
  return <ContainerWrapper {...props}>{children}</ContainerWrapper>
}

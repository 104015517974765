import { Stack, StackProps, YStack, isWeb } from '@my/ui'
import { Footer } from '../footer'
import { NAV_HEIGHT, NAV_HEIGHT_SM, Navigation } from '../navigation/navigation.web'
import { IFrameNavigation } from '../navigation/iframe-navigation.web'
import { useIFrame } from 'app/provider/iframe'

interface MainLayoutProps {
  children: React.ReactNode
  maxViewHeight?: boolean
  hideFooter?: boolean
  isIframe?: boolean
}

export const MainLayout = ({
  children,
  maxViewHeight,
  hideFooter,
  isIframe,
  ...props
}: MainLayoutProps & StackProps) => {
  const { isIframe: isIframeHook } = useIFrame()

  const isIframeFinal = isIframe || isIframeHook

  return (
    <Stack
      className="MainLayout"
      bg="$background"
      flexGrow={1}
      height={isWeb && maxViewHeight ? '100vh' : '100%'}
      minHeight={isWeb ? '100vh' : undefined}
      {...props}
    >
      {isWeb && !isIframeFinal && (
        <Navigation
          style={{
            width: '100%',
            position: 'fixed' as 'absolute',
            zIndex: 2,
          }}
        />
      )}
      {isWeb && isIframeFinal && <IFrameNavigation w="100%" pos={'fixed' as 'absolute'} zIndex="$2" />}
      <YStack
        zIndex={1}
        flexGrow={1}
        height="100%"
        {...(isWeb && {
          pt: !isIframeFinal ? NAV_HEIGHT : 0,
          $gtSm: {
            pt: !isIframeFinal ? NAV_HEIGHT_SM : 0,
          },
        })}
      >
        {children}
      </YStack>
      {isWeb && !hideFooter && !isIframeFinal && <Footer />}
    </Stack>
  )
}

import { PopoverContentProps } from '@my/ui'
import { useAuth } from 'app/provider/auth'
import { NotificationsDropdown } from './notifications-dropdown'
import {
  activityNotificationsQuery,
  useActivityNotificationCountQuery,
  useActivityNotificationsQuery,
} from '../../queries'
import { useSetNotificationRead } from '../../commands/use-set-notification-read'
import { useEffect } from 'react'

interface NotificationsDropdownContainerProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  anchor: React.ReactNode
  contentProps?: PopoverContentProps
}

export const NotificationsDropdownContainer = (props: NotificationsDropdownContainerProps) => {
  const { user } = useAuth()
  const { data: notificationCountData, refetch: refetchNotificationCount } =
    useActivityNotificationCountQuery({
      skip: !user?.id,
    })

  const { loading, data } = useActivityNotificationsQuery({
    variables: {
      ...activityNotificationsQuery.defaultVariables,
    },
    skip: !props.open || !user?.id,
    fetchPolicy: 'cache-and-network',
  })

  const [setNotificationRead] = useSetNotificationRead()

  const handleNotificationRead = async (notificationId?: string) => {
    await setNotificationRead({ variables: { notificationId } })
  }

  useEffect(() => {
    if (props.open) {
      handleNotificationRead()
    }
  }, [props.open])

  const activityNotifications = data?.ActivityNotifications?.docs || []

  return (
    <NotificationsDropdown
      open={props.open}
      anchor={props.anchor}
      loading={loading}
      activityNotifications={activityNotifications}
      contentProps={props.contentProps}
      hasUnread={(notificationCountData?.ActivityNotifications?.totalDocs || 0) > 0}
      totalUnread={notificationCountData?.ActivityNotifications?.totalDocs || 0}
      onOpenChange={props.onOpenChange}
    />
  )
}

import { Facebook, Mail, Twitter } from '@tamagui/lucide-icons'

export interface SocialShareLinkOptions {
  type:
    | FacebookShareLinkOptions['type']
    | TwitterShareLinkOptions['type']
    | EmailShareLinkOptions['type']
  sharingLink: string
}

export interface FacebookShareLinkOptions extends SocialShareLinkOptions {
  type: 'facebook'
}

export interface TwitterShareLinkOptions extends SocialShareLinkOptions {
  type: 'twitter'
}

export interface EmailShareLinkOptions extends SocialShareLinkOptions {
  type: 'email'
  subject?: string
}

export interface SocialShareLink {
  icon: any
  label: string
  href: string
}

export class SocialShareLinkBuilder {
  static build<T extends SocialShareLinkOptions>(options: T): SocialShareLink {
    switch (options.type) {
      case 'facebook':
        return this.buildFacebookLink(options as FacebookShareLinkOptions)

      case 'twitter':
        return this.buildTwitterLink(options as TwitterShareLinkOptions)

      case 'email':
        return this.buildEmailLink(options as EmailShareLinkOptions)

      default:
        throw new Error(`Unknown social share link type: ${options.type}`)
    }
  }

  private static buildFacebookLink(options: FacebookShareLinkOptions): SocialShareLink {
    return {
      icon: Facebook,
      label: 'Facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        options.sharingLink
      )}`,
    }
  }

  private static buildTwitterLink(options: TwitterShareLinkOptions): SocialShareLink {
    return {
      icon: Twitter,
      label: 'Twitter',
      href: `https://twitter.com/intent/tweet?url=${encodeURIComponent(options.sharingLink)}`,
    }
  }

  private static buildEmailLink(options: EmailShareLinkOptions): SocialShareLink {
    return {
      icon: Mail,
      label: 'Email',
      href: `mailto:?subject=${
        options.subject || 'Look what I have found!'
      }&body=${encodeURIComponent(options.sharingLink)}`,
    }
  }
}

import { Dialog, SizableText, YStack } from '@my/ui'

import { ShareModalProps } from './share-modal.props'
import { ShareContent } from '../share-content'
import { X } from '@tamagui/lucide-icons'

export const ShareModal = (props: ShareModalProps) => {
  return (
    <Dialog>
      <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
      <Dialog.Adapt when="sm">
        <Dialog.Sheet snapPoints={[58]} modal dismissOnSnapToBottom>
          <Dialog.Sheet.Overlay bg="$backdrop" />
          <Dialog.Sheet.Handle bg="$white" opacity={0.9} />
          <Dialog.Sheet.Frame zIndex="$5" flexGrow={1}>
            <Dialog.Adapt.Contents />
          </Dialog.Sheet.Frame>
        </Dialog.Sheet>
      </Dialog.Adapt>

      <Dialog.Portal>
        <Dialog.Overlay
          key="overlay"
          animation="quick"
          bg="$backdrop"
          o={0.33}
          enterStyle={{ o: 0 }}
          exitStyle={{ o: 0 }}
        />
        <Dialog.Content
          borderRadius="$4"
          elevate
          px="$0"
          key="content"
          position="relative"
          maxWidth={700}
          w="100%"
          mx="$2"
          py="$6"
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ y: 10, opacity: 0, scale: 0.95 }}
          scale={1}
          opacity={1}
          y={0}
        >
          <Dialog.Close cursor="pointer" pos="absolute" top="$-7" right="$-2">
            <X size={36} color="white" />
          </Dialog.Close>
        
          <YStack ai="center" jc="center" p="$3.5" gap="$4">
            <Dialog.Title jc="center">
              <SizableText
                textAlign="center"
                size="$7"
                fow="600"
                $gtMd={{
                  size: '$8',
                  fow: '600',
                }}
              >
                Share via
              </SizableText>
            </Dialog.Title>

            <ShareContent href={props.href} subject={props.subject} />
          </YStack>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}

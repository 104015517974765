import {
  Box,
  Button,
  Container,
  PopoverContentProps,
  ScrollView,
  SizableText,
  SmartLink,
  Spinner,
  Stack,
  TPopover,
  XStack,
  YStack,
  colors,
  shadowStandard,
} from '@my/ui'
import { Frown } from '@tamagui/lucide-icons'
import { ActivityNotificationsQueryOutput } from '../../queries'
import { NotificationBlockRenderer } from '../../blocks'

export interface NotificationsDropdownProps {
  open: boolean
  loading: boolean
  anchor: React.ReactNode
  activityNotifications: ActivityNotificationsQueryOutput['ActivityNotifications']['docs']
  contentProps?: PopoverContentProps
  // TODO: Implement this here
  hasUnread: boolean
  totalUnread: number
  onOpenChange: (open: boolean) => void
}

export const NotificationsDropdown = (props: NotificationsDropdownProps) => {
  return (
    <TPopover open={props.open} onOpenChange={props.onOpenChange} placement="bottom-end" allowFlip>
      <TPopover.Anchor pos="relative">
        {props.anchor}
        {props.hasUnread && (
          <Stack w={16} h={16} bg="$red6" pos="absolute" top="$-1.5" right="$-1.5" br={1000} jc="center" ai="center">
            <SizableText size="$0.5" color="$white" fow="600">
              {props.totalUnread > 99 ? '99+' : props.totalUnread}
            </SizableText>
          </Stack>
        )}
      </TPopover.Anchor>
      <TPopover.Content
        bg="$white"
        width={390}
        p="$0"
        mt="$2"
        br="$3"
        overflow="hidden"
        {...shadowStandard}
        {...props.contentProps}
      >
        <Box w="100%" jc="space-between" gap="$3.5" fd="row" shadow={false}>
          <SizableText size="$6" fow="600">
            Notifications
          </SizableText>
          <SmartLink Component={Button} theme="gray" href="/notifications" size="$3">
            View All
          </SmartLink>
        </Box>
        <ScrollView w="100%" maxHeight={450}>
          {props.activityNotifications.length > 0 &&
            props.activityNotifications.map((item) => (
              <NotificationBlockRenderer
                key={item.id}
                block={item.notification[0]!}
                createdAt={item.createdAt}
              />
            ))}

          {!props.loading && props.activityNotifications.length === 0 && (
            <Container gap="$3.5" jc="center" ai="center" pt="$3.5" pb="$6" gutter>
              <YStack br={1000} p="$2.5" jc="center" ai="center" bg="$white" {...shadowStandard}>
                <Frown color={colors.primary} width={24} height={24} />
              </YStack>
              <YStack gap="$2" ai="center" jc="center">
                <SizableText textAlign="center" size="$4" color="$overlay12" fow="600">
                  Sorry, there’s no notifications available at the moment
                </SizableText>
              </YStack>
            </Container>
          )}

          {props.loading && (
            <XStack w="100%" jc="center" ai="center" h={100}>
              <Spinner color="$black" />
            </XStack>
          )}
        </ScrollView>
      </TPopover.Content>
    </TPopover>
  )
}

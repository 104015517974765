import AsyncStorage from '@react-native-async-storage/async-storage';

export const useCurrentRoute = () => {
  const getCurrentRoute = () => {
    const currentRouteName = window.location.pathname;
    return currentRouteName;
  };

  const setCurrentRoute = async (routeName) => {
    try {
      await AsyncStorage.setItem('currentRoute', routeName);
    } catch (error) {
      console.error('Error saving current route:', error);
    }
  };

  const getPreviousRoute = async () => {
    try {
      const previousRoute = await AsyncStorage.getItem('currentRoute');
      return previousRoute;
    } catch (error) {
      console.error('Error getting previous route:', error);
      return null;
    }
  };

  const removePreviousRoute = async () => {
    try {
      await AsyncStorage.removeItem('currentRoute');
    } catch (error) {
      console.error('Error removing previous route:', error);
    }
  };

  return {
    getCurrentRoute,
    setCurrentRoute,
    getPreviousRoute,
    removePreviousRoute,
  };
};

import { YStack, YStackProps } from '@my/ui'
import React from 'react'

export const BlurBackground = ({ ...props }: YStackProps) => {
  return (
    <YStack
      pos="absolute"
      fullscreen
      bg="$backdrop"
      style={
        {
          backdropFilter: 'blur(6px)',
        } as any
      }
      {...props}
    />
  )
}

import { GetProps, SizableText, styled } from 'tamagui'
import { SmartLink } from '../smart-link'

const StyledTextLink = styled(SizableText, {
  textDecorationLine: 'underline',
  hoverStyle: {
    opacity: 0.8,
  },
  focusStyle: {
    opacity: 0.8,
  },
  pressStyle: {
    opacity: 0.9,
  },
})

type StyledTextLinkProps = GetProps<typeof StyledTextLink>

export type TextLinkProps = {
  href: string
} & StyledTextLinkProps

export const TextLink = ({ href, children, ...props }: TextLinkProps) => {
  return (
    <SmartLink Component={StyledTextLink} href={href} textDecorationLine="underline" {...props}>
      {children}
    </SmartLink>
  )
}
